import { createClient } from "contentful";
import { CONTENTFUL_ACCESS_TOKEN, CONTENTFUL_SPACE } from "../conf";

const getEntries = () => {
	const client = createClient({
		space: CONTENTFUL_SPACE,
		accessToken: CONTENTFUL_ACCESS_TOKEN,
	});
	return client.getEntries();
};

export { getEntries };
